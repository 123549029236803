<template>
    <div class="a-flex-cfsc" id="chargeMapBox">
        <div id="chargeMapContent" :style="{width:'8.4rem',height:mapHight}"></div>
        <div class="mapCoverbox a-fs-14 a-flex-cfsfs" v-if="contentName">
            <span class="a-fs-16 a-fw-700">{{ contentName }}</span>
            <span>{{ stationAddress }}</span>
            <span>设备数量：{{ stationDevNum || 0 }}</span>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            mapContent: null,
            mycenter: null,
            mapPoints: [],//地图点位  
            marker: null,
            stationChargePlan: '',
            contentName: null,
            buildId: null,
            stationDevNum: 0,
            stationAddress: '',
            mapHight: '500px'
        };
    },
    // computed: {
    //     mapHight () {
    //         this.$nextTick(()=>{
                
    //         })
    //     }  
    // },
    mounted() {
        
        this.$nextTick(()=>{
            let h = document.getElementById('chargeMapBox').offsetHeight
            this.mapHight = h?h+'px':'5.2rem'
            this.initMap()
            this.getMapList()
        })
    },
    methods: {
        initMap() {
            //定义map变量，调用 TMap.Map() 构造函数创建地图
            this.mapContent = new TMap.Map(document.getElementById('chargeMapContent'), {
                center: new TMap.LatLng(36.550339,104.114129),//设置中心点坐标, 
                zoom: 4,
                pitch: 0, // 设置地图俯仰角
                mapStyleId: 'style1',
                baseMap: {
            		type: "vector",
            		features: ["base", "building3d"], // 隐藏矢量文字
          		},
            });
            this.mapContent.removeControl(TMap.constants.DEFAULT_CONTROL_ID.SCALE);
            this.mapContent.removeControl(TMap.constants.DEFAULT_CONTROL_ID.ZOOM);
            this.mapContent.removeControl(TMap.constants.DEFAULT_CONTROL_ID.ROTATION);

        },
        setMarker() {
            let geometries = []
            let coords = []

            this.mapPoints.map((item, index) => {
                if (Number(item.lat) && Number(item.lng)) {
                    geometries.push({ //点标注数据数组
                        "id": "demo",
                        "styleId": "marker",
                        "position": new TMap.LatLng(Number(item.lat), Number(item.lng)),
                        "properties": {
                            "content": item.stationName,
                            'buildId': item.stationId,
                            'stationChargePlan': item.stationChargePlan,
                            'stationDevNum': item.devNum,
                            'stationAddress': item.stationAddress
                        },
                    })
                    coords.push(new TMap.LatLng(Number(item.lat), Number(item.lng)))
                }
            })

            if (this.mapPoints.length) {
                this.marker = new TMap.MultiMarker({
                    id: "marker-layer", //图层id
                    map: this.mapContent,
                    styles: { //点标注的相关样式
                        "marker": new TMap.MarkerStyle({
                            "width": 32,
                            "height": 44,
                            "anchor": { 'x': 16, 'y': 44 },
                            "src": require("@/assets/icon/map-marker-icon.png")
                        }),
                        
                    },
                    geometries: geometries
                });

                // 自适应缩放等级 以包含所有的标记点 https://lbs.qq.com/Vis/JavascriptAPI/APIGuide/map/mapState
                // let latlngBounds = new TMap.LatLngBounds();
                // for (var i = 0, l = coords.length; i < l; i++) {
                //     latlngBounds.extend(coords[i]);
                // }
                // this.mapContent.fitBounds(latlngBounds, {
                //     padding: 100 // 自适应边距
                // });
                this.marker.on('mouseover', (evt) => {
                    this.$set(this, 'contentName', evt.geometry.properties.content)
                    this.$set(this, 'buildId', evt.geometry.properties.buildId)
                    this.$set(this, 'stationChargePlan', evt.geometry.properties.stationChargePlan)
                    this.$set(this, 'stationDevNum', evt.geometry.properties.stationDevNum)
                    this.$set(this, 'stationAddress', evt.geometry.properties.stationAddress)
                })
                this.marker.on('mouseout', () => {
                    setTimeout(() => {
                        this.$set(this, 'contentName', '')
                        this.$set(this, 'buildId', '')
                        this.$set(this, 'stationChargePlan', '')
                        this.$set(this, 'stationDevNum', '')
                        this.$set(this, 'stationAddress', '')
                    }, 5000)
                })
            }
        },
        getMapList() {
            this.$Axios._post({
                url: this.$Config.apiUrl.searchStationForDataShow,
                params: {
                    pageNumber: 1,
                    pageSize: 5000,
                    stationName: "",
                    stationId: "",
                }
            }).then(res => {
                if (res.result.code == 0) {
                    if (res.result.data.list.length) {
                        this.mapPoints = res.result.data.list;

                        this.setMarker()
                    }

                }
                else {
                    this.$message.error(res.result.message);
                }
            }).catch((error) => {
                this.$message.error(error);
            })
        },
    },
};
</script>

<style lang="scss" scoped>
.mapCoverbox {
    cursor: pointer;
    width: 3.6rem;
    background: rgba(255, 255, 255, 0.96);
    box-shadow: 0 0.04rem 0.16rem 0 rgba(139, 147, 167, 0.2500);
    border-radius: 0.04rem;
    padding: 0.08rem 0.16rem;
    position: absolute;
    top: 0.32rem;
    left: 0.34rem;
    z-index: 100;

    &:hover {
        color: #007AFF;
    }

}
</style>