import { render, staticRenderFns } from "./charge-num.vue?vue&type=template&id=54ea1375&scoped=true&"
import script from "./charge-num.vue?vue&type=script&lang=js&"
export * from "./charge-num.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54ea1375",
  null
  
)

export default component.exports