<template>
    <div class="numberScroll" :style="numStyle">
        <div class="screenMini" :style="minStyle" v-for="(item,index) in numberToArray" :key="index">
            <div :style="reStyle(item)">
                <div :style="minDivStyle" v-for="(v,i) in numberBox" :key="i">{{v}}</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            numberBox: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, '.', ','],
            numberToArray: []
        }
    },
    props: {
        /**
         * 传入值String, Number
         */
        number: {
            type: [String, Number],
            default: ''
        },
        fontsize: {
            default: 0.7
        },
        minw: {
            default: 0.4
        },
        minh: {
            default: 0.65
        }
    },
    computed:{
        numStyle () {
            return 'font-size:' + this.fontsize + 'rem;height:' + this.minh + 'rem'
        },
        minStyle () {
            return 'width:' + this.minw + 'rem;height:' + this.minh + 'rem'
        },
        minDivStyle () {
            return 'height:' + this.minh + 'rem'
        }
    },
    methods: {
        reStyle (number) {
            if(number==='.'){
                return `transform:translate3d(0,-${+10 * this.minh}rem,0)`
            }else if(number===','){
                return `transform:translate3d(0,-${+11 * this.minh}rem,0)`
            }else{
                return `transform:translate3d(0,-${+number * this.minh}rem,0)`
            }
        }
    },
    watch: {
        number: {
            immediate: true,
            deep: true,
            handler (num) {
                let result = [], counter = 0, decimal='', decimalArr = [];
                decimal = (num || 0).toString().split('.')[1]//取小数部分
                decimal=decimal?decimal.substring(0,2):'';//小数部分保留两位
                decimalArr=decimal?decimal.split(''):[]
                if(decimalArr.length){
                    decimalArr.unshift('.')
                }
                num = (num || 0).toString().split('.')[0];//取整数部分
                for (var i = num.length - 1; i >= 0; i--) {
                    counter++;
                    result.unshift(num[i]);
                    // if (!(counter % 3) && i != 0) 
                    // {
                    //     // result.unshift(','); 
                    //     result.unshift(','); 
                    // }
                }
                let arr = result.concat(decimalArr)

                this.numberToArray = new Array(arr.length).fill(0)
                setTimeout(() => {
                    this.numberToArray = arr
                }, 10)
            }
        }
    }
}
</script>
<style>
@font-face {
    font-family: unidreamLED;
    src: url("../../../assets/fontFamily/UnidreamLED.ttf");
}
.numberScroll{
    display: flex;
    align-items: center;
    color: #FFFFFF;
    /* margin-left: 0.06rem; */
    font-family: unidreamLED;
}
.screenMini{
    /* height: .42rem;
    width: .2rem; */
    overflow: hidden;
}
.screenMini>div{
    display: flex;
    flex-direction: column;
    transform: translate3d(0,0,0);
    transition: all 1.5s;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}
.screenMini>div>div{
    display: flex;
    align-items: flex-end;
    justify-content: center;
    line-height:1;
    overflow: hidden;
}
</style>
