<template>
    <div class="a-flex-cfsc">
        <div id="myChartMonthTrade" class="a-flex-1 a-w-100"></div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            lineChartMonth: null,
            startTime: '',
            endTime: '',
            option: {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#6a7985'
                        }
                    },
                },
                // legend: {
                //     textStyle:{
                //         color: '#ffffff'
                //     },
                //     itemGap: 40,
                //     x: 'right'
                // },
                grid: {
                    left: '1%',
                    right: '4%',
                    bottom: '5%',
                    top: '5%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: false,
                        data: []                        
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        splitLine: {
                            lineStyle: {
                                color: '#444444',
                                width: 0
                            }
                        },
                        axisLabel:{
                            formatter:function(val,index){
                                if(val<10000){
                                    return val
                                }else{
                                    return Number((val/10000).toFixed(1)) + 'w'
                                }
                            }
                        },
                    }
                ],
                series: [
                    {
                        name: '交易额',
                        type: 'line',
                        smooth: true,
                        areaStyle:{
                            origin:'start',
                            color: {
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [{
                                    offset: 0, color: '#FAD961' // 0% 处的颜色
                                }, {
                                    offset: 1, color: '#F76B1C' // 100% 处的颜色
                                }],
                                global: false // 缺省为 false
                            },
                            opacity: 0.2
                        },
                        lineStyle:{
                            color: {
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [{
                                    offset: 0, color: '#FAD961' // 0% 处的颜色
                                }, {
                                    offset: 1, color: '#F76B1C' // 100% 处的颜色
                                }],
                                global: false, // 缺省为 false
                            },
                            width: 2
                        },
                        symbolSize: [24,24],
                        symbol: 'image://https://sxwhapp.oss-cn-beijing.aliyuncs.com/TZ/dev/cate/abcb8e390dcc44978a1ba48149b90c94.png',
                        emphasis:{
                            itemStyle:{
                                opacity:1
                            },
                            lineStyle:{
                                width: 3
                            }
                        },
                        itemStyle:{
                            color: '#FAD961',
                        },
                        showSymbol: false,
                        data: []
                    }
                ]
            }
        };
    },

    mounted() {
        this.startTime = this.$getDay.getTodayThatYear(1,'YYYY-MM-DD HH:mm:ss')
        this.endTime = this.$getDay.getToday('YYYY-MM-DD HH:mm:ss')
        let _this = this
        _this.$nextTick(_ => {   
            _this.drawLine()
            window.addEventListener("resize", function() {        
                _this.lineChartMonth.resize();     
            });
        });
    },
    beforeDestroy () {
        window.removeEventListener("resize", ()=>{});
    },
    methods: {
        drawLine(){
            // 基于准备好的dom，初始化echarts实例
            this.lineChartMonth = this.$echarts.init(document.getElementById('myChartMonthTrade'))
            // 绘制图表
            
            this.$Axios._post({
                url: this.$Config.apiUrl.totalTransactionAmount,
                method: "post",
                params: {
                    startTime:this.startTime,
                    endTime:this.endTime
                }
            }).then(res => {
                if(res.result.code == 0){                        
                    let xAxis = []
                    let data = []
                    res.result.data.map(item=>{
                        xAxis.push(item.month)
                        data.push(Number((item.amount/100).toFixed(2)))
                    })
                    this.option.xAxis[0].data = xAxis
                    this.option.series[0].data = data
                    this.lineChartMonth.setOption(this.option,true);
                    // this.lineChartMonth.resize();
                }else{
                    this.$message.error(res.result.message)
                }
            })
            
        },
    },
};
</script>
<style lang="scss" scoped>

</style>