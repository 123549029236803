<template>
    <div class="a-flex-cfsc" id="myChartPriceContent">
        <div id="myChartPrice" class="a-flex-1 a-w-100"></div>
    </div>
</template>

<script>
import customerPrice from "./customerPrice.json";
export default {
    data() {
        return {
            lineChartPrice: null,
            option: {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#6a7985'
                        }
                    }
                },
                // legend: {
                //     textStyle:{
                //         color: '#ffffff'
                //     },
                //     itemWidth: 8,
                //     itemHeight: 8,
                //     itemGap: 40,
                //     x: 'right'
                // },
                grid: {
                    left: '1%',
                    right: '4%',
                    bottom: '5%',
                    top: '10%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: true,
                        data: []                        
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        splitLine: {
                            lineStyle: {
                                color: '#444444',
                                width: 0
                            }
                        }
                    }
                ],
                series: [
                    {
                        name: '客单价',
                        type: 'bar',
                        barWidth: 10,
                        label: {
                            normal:{
                                show:true,
                                position: 'top',
                                textStyle:{
                                    color: '#606366',
                                }
                                
                            }
                        },
                        itemStyle: {
                            normal:{
                                color: '#6699FF',
                                barBorderRadius:[4,4,0,0],
                            }
                        },
                        data: []
                    }
                ]
            }
        };
    },

    mounted() {
        let _this = this
        _this.$nextTick(_ => {   
            _this.drawLine()
            window.addEventListener("resize", function() {        
                _this.lineChartPrice.resize();     
            });
        });
        
    },
    beforeDestroy () {
        window.removeEventListener("resize", ()=>{});
    },
    methods: {
        drawLine(){
            // 基于准备好的dom，初始化echarts实例
            this.lineChartPrice = this.$echarts.init(document.getElementById('myChartPrice'))

            // 绘制图表
            let data = []
            let xAxis = []
            customerPrice.RECORDS.map((item,index)=>{
                data.push(Number(item.values))
                xAxis.push(item.time)
            })
            this.option.series[0].data = data.sort()
            this.option.xAxis[0].data = xAxis
            this.lineChartPrice.setOption(this.option,true);
            // this.lineChartPrice.resize();
            // this.$api.lineChartPriceData({
            //     lastDays: 15
            // }).then(res=>{
            //     if(res.code == 0){
            //         // 会员及以上
            //         this.option.series[0].data = []
            //         res.content.numTotal.map((item,index)=>{
            //             let data = item-res.content.numVip1[index] < 0?0:item-res.content.numVip1[index]
            //             this.option.series[0].data.push(data)
            //         })
            //         // 普通用户
            //         this.option.series[1].data = res.content.numVip1

            //         this.lineChartPrice.setOption(this.option);
            //     }else{

            //     }
            // }).catch(err=>{

            // })
            
        },
    },
};
</script>
<style lang="scss" scoped>

</style>