<template>
    <div class="a-flex-cfsc" id="hotMapBox">
        <div id="hotMapContent" :style="{width:'8.4rem',height:mapHight}"></div>
    </div>
</template>

<script>
import heatJson from '../../../utils/heat.json'
export default {
    data() {
        return {
            mapContent: null,
            mycenter: null,
            mapPoints: [],//地图点位  
            marker: null,
            heatJson: [],
            heatMap: null,//热力图层
            mapHight: '500px'
        };
    },

    mounted() {
        this.$nextTick(()=>{
            let h = document.getElementById('hotMapBox').offsetHeight
            this.mapHight = h?h+'px':'5.2rem'
            this.initMap()
            this.getMapList()
        })
        
    },

    methods: {
        initMap() {
            //定义map变量，调用 TMap.Map() 构造函数创建地图
            this.mapContent = new TMap.Map(document.getElementById('hotMapContent'), {
                center: new TMap.LatLng(36.550339,104.114129),//设置中心点坐标, 
                zoom: 4,
                pitch: 0, // 设置地图俯仰角
                mapStyleId: 'style1',
                baseMap: {
            		type: "vector",
            		features: ["base", "building3d"], // 隐藏矢量文字
          		},
            });
            this.mapContent.removeControl(TMap.constants.DEFAULT_CONTROL_ID.SCALE);
            this.mapContent.removeControl(TMap.constants.DEFAULT_CONTROL_ID.ZOOM);
            this.mapContent.removeControl(TMap.constants.DEFAULT_CONTROL_ID.ROTATION);

            //初始化热力图并添加至map图层
            this.heatMap =  new TMap.visualization.Heat({
                max: 20, // 热力最强阈值
                min: 0, // 热力最弱阈值
                height: 0, // 峰值高度
                radius: 35 // 最大辐射半径
            })
            .addTo(this.mapContent)

        },

        getMapList() {
            // this.heatJson = heatJson.RECORDS.map((item,index)=>{
            //                 return {
            //                     lat: Number(item.lngLatStr.split(',')[1]),
            //                     lng: Number(item.lngLatStr.split(',')[0]),
            //                     // lat: Number(item.lat),
            //                     // lng: Number(item.lng),
            //                     count: item.deviceCount
            //                 }
            //             })
            //             this.heatMap.setData(this.heatJson);//设置数据
            //             return
            this.$Axios._post({
                url: this.$Config.apiUrl.historyChargeMap,
                params: {}
            }).then(res => {
                if (res.result.code == 0) {
                    if (res.result.data.deviceMapList.length) {
                        this.heatJson = res.result.data.deviceMapList.map((item,index)=>{
                            return {
                                lat: Number(item.lat),
                                lng: Number(item.lng),
                                count: item.deviceCount
                            }
                        })
                        this.heatMap.setData(this.heatJson);//设置数据
                    }
                }
                else {
                    this.$message.error(res.result.message);
                }
            }).catch((error) => {
                this.$message.error(error);
            })
        },
    },
};
</script>

<style lang="scss" scoped>

</style>