<template>
    <div class="a-flex-cfsc">
        <div id="myChartCharge" class="a-flex-1 a-w-100"></div>
    </div>
</template>

<script>
import chargeNum from './chargeNum.json'

export default {
    data() {
        return {
            myChartCharge: null,
            option: {
                color: ['#80FFA5', '#00DDFF', '#37A2FF', '#F56C6C', '#FFBF00'],
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                    type: 'cross',
                    label: {
                        backgroundColor: '#6a7985'
                    }
                    }
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '5%',
                    top: '5%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: [2018,2019,2020,2021,2022,2023]
                },
                yAxis: [
                    {
                        type: 'value',
                        splitLine: {
                            lineStyle: {
                                color: '#444444',
                                width: 0
                            }
                        },
                        axisLabel:{
                            formatter:function(val,index){
                                if(val<10000){
                                    return val
                                }else{
                                    return Number((val/10000).toFixed(1)) + 'w'
                                }
                            }
                        },
                    }
                ],
                series: [
                    {
                        name: '1-5次',
                        type: 'line',
                        stack: 'Total',
                        smooth: true,
                        lineStyle: {
                            width: 0
                        },
                        showSymbol: false,
                        areaStyle: {
                            opacity: 0.8,
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                                offset: 0,
                                color: 'rgb(128, 255, 165)'
                            },
                            {
                                offset: 1,
                                color: 'rgb(1, 191, 236)'
                            }
                            ])
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        data: []
                    },
                    {
                        name: '5-10次',
                        type: 'line',
                        stack: 'Total',
                        smooth: true,
                        lineStyle: {
                            width: 0
                        },
                        showSymbol: false,
                        areaStyle: {
                            opacity: 0.8,
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                                offset: 0,
                                color: 'rgb(0, 221, 255)'
                            },
                            {
                                offset: 1,
                                color: 'rgb(77, 119, 255)'
                            }
                            ])
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        data: []
                    },
                    {
                        name: '10-20次',
                        type: 'line',
                        stack: 'Total',
                        smooth: true,
                        lineStyle: {
                            width: 0
                        },
                        showSymbol: false,
                        areaStyle: {
                            opacity: 0.8,
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                                offset: 0,
                                color: 'rgb(55, 162, 255)'
                            },
                            {
                                offset: 1,
                                color: 'rgb(116, 21, 219)'
                            }
                            ])
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        data: []
                    },
                    {
                        name: '20次以上',
                        type: 'line',
                        stack: 'Total',
                        smooth: true,
                        lineStyle: {
                            width: 0
                        },
                        showSymbol: false,
                        areaStyle: {
                            opacity: 0.8,
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                                offset: 0,
                                color: '#F56C6C'
                            },
                            {
                                offset: 1,
                                color: '#F56C6C'
                            }
                            ])
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        data: []
                    },
                ]
            },
            chargeNumInterval: null,
        };
    },

    mounted() {
        let _this = this
        
        _this.$nextTick(_ => {   
            _this.drawLine()
            window.addEventListener("resize", function() {        
                _this.myChartCharge.resize();     
            });
        });
        this.chargeNumInterval = setInterval(()=>{
            this.myChartCharge.clear()
            this.drawLine()
        },10000)
    },
    beforeDestroy () {
        window.removeEventListener("resize", ()=>{});
        clearInterval(this.chargeNumInterval)
    },
    methods: {
        
        drawLine(){
            // 基于准备好的dom，初始化echarts实例
            this.myChartCharge = this.$echarts.init(document.getElementById('myChartCharge'))
            // 绘制图表
            
            // setInterval(()=>{
            //     this.option.series[0].data = [20,30,45,88,22,35,15,25]
            //     this.option.series[1].data = [25,20,15,8,29,15,5,60]
            //     this.option.series[2].data = [12,18,90,15,45,55,2,77]
            //     this.option.series[3].data = [30,10,12,26,19,32,5,18]
            //     this.myChartCharge.clear()
            //     this.myChartCharge.setOption(this.option,true);
            // },3000)
            let data1 = []
            let data2 = []
            let data3 = []
            let data4 = []
            chargeNum.RECORDS.map((item,index)=>{
                if(item.typeCode == 1){
                    data1.unshift(item.values)
                }else if(item.typeCode == 2){
                    data2.unshift(item.values)
                }else if(item.typeCode == 3){
                    data3.unshift(item.values)
                }else if(item.typeCode == 4){
                    data4.unshift(item.values)
                }
            })
            this.option.series[0].data = data1
            this.option.series[1].data = data2
            this.option.series[2].data = data3
            this.option.series[3].data = data4
            this.myChartCharge.setOption(this.option);
            // this.myChartCharge.resize();
            // this.$api.myChartChargeData({
            //     lastDays: 15
            // }).then(res=>{
            //     if(res.code == 0){
            //         // 会员及以上
            //         this.option.series[0].data = []
            //         res.content.numTotal.map((item,index)=>{
            //             let data = item-res.content.numVip1[index] < 0?0:item-res.content.numVip1[index]
            //             this.option.series[0].data.push(data)
            //         })
            //         // 普通用户
            //         this.option.series[1].data = res.content.numVip1

            //         this.myChartCharge.setOption(this.option);
            //     }else{

            //     }
            // }).catch(err=>{

            // })
            
        },
    },
};
</script>
<style lang="scss" scoped>

</style>