<template>
    <div class="data-layout a-flex-rfsfs">
        <div class="data-left a-flex-cfsc">
            <div class="data-card a-flex-1 a-flex-cfsfs stretch">
                <span class="title">今日数据</span>
                <div class="a-flex-1 a-flex-rfsfs a-flex-wrap">
                    <div class="a-flex-cfsfs num-box" style="width:100%">
                        <span>订单交易额</span>
                        <div class="a-flex-rfsfs" style="align-items:baseline">
                            <span style="font-size:0.28rem">￥</span>
                            <scroll-num :number='amount+packageMoney' :minw="0.25" :minh="0.5" :fontsize="0.5"></scroll-num>
                        </div>
                    </div>
                    <div class="a-flex-cfsfs num-box">
                        <span >订单总数</span>
                        <scroll-num :number='orderNum' :minw="0.18" :minh="0.32" :fontsize="0.32"></scroll-num>
                    </div>
                    <div class="a-flex-cfsfs num-box">
                        <span >正在充电数</span>
                        <scroll-num :number='chargeNum' :minw="0.18" :minh="0.32" :fontsize="0.32"></scroll-num>
                    </div>
                    <div class="a-flex-cfsfs num-box ">
                        <span >新增用户</span>
                        <scroll-num :number='userNum' :minw="0.18" :minh="0.32" :fontsize="0.32"></scroll-num>
                    </div>
                    <div class="a-flex-cfsfs num-box">
                        <span>套餐办理</span>
                        <scroll-num :number='packageNum' :minw="0.18" :minh="0.32" :fontsize="0.32"></scroll-num>
                    </div>
                    <div class="a-flex-cfsfs num-box ">
                        <span >套餐金额</span>
                        <div class="a-flex-rfsfs" style="align-items:baseline">
                            <span style="font-size:0.18rem">￥</span>
                            <scroll-num :number='packageMoney' :minw="0.2" :minh="0.32" :fontsize="0.32"></scroll-num>
                        </div>
                    </div>
                </div>
            </div>
            <div class="data-card a-flex-2 a-flex-cfsfs stretch">
                <span class="title">统计数据（截止到昨日）</span>
                <div class="a-flex-1 a-flex-rfsfs a-flex-wrap">
                    <div class="a-flex-cfsfs num-box" style="width:100%">
                        <span>累计销售额</span>
                        <div class="a-flex-rfsfs" style="align-items:baseline">
                            <span style="font-size:0.28rem">￥</span>
                            <div style="font-size:0.5rem">{{  historyAmount }}</div>
                        </div>
                    </div>
                    <div class="a-flex-cfsfs num-box">
                        <span >毛豆充注册用户</span>
                        <div >{{ userTotal }}</div>
                    </div>
                    <div class="a-flex-cfsfs num-box ">
                        <span >有效注册车辆</span>
                        <div >{{ bikeTotal }}</div>
                    </div>
                    <div class="a-flex-cfsfs num-box">
                        <span>人车比对异常处理次数</span>
                        <div >48</div>
                    </div>
                    
                    <div class="a-flex-cfsfs num-box ">
                        <span>异常充电保护次数</span>
                        <div >{{ protectTotal }}</div>
                    </div>
                    <div class="a-flex-cfsfs num-box">
                        <span>烟感报警次数</span>
                        <div>2</div>
                    </div>
                    <div class="a-flex-cfsfs num-box">
                        <span>节约能效</span>
                        <div>{{ saveEnergyTotal }}</div>
                    </div>
                    <div class="a-flex-cfsfs num-box">
                        <span>销售会员卡金额</span>
                        <div class="a-flex-rfsfs" style="align-items:baseline">
                            <span style="font-size:0.18rem">￥</span>
                            <div>{{ historyMonthcardPrice }}</div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        <div class="data-center a-flex-cfsc">
            <div class="a-flex-rcc data-title">
                <span>玖电物联平台数控中心</span>
            </div>
            <div class="data-card a-flex-1">
                <el-carousel :autoplay="true" :interval="10000" loop style="height:100%" indicator-position="none">
                    <el-carousel-item style="height:100%" class="a-flex-cfsfs stretch">
                        <span class="title">充电桩分布图</span>
                        <div class="a-flex-1 map-content a-flex-cfsfs stretch">
                            <charge-map class="a-flex-1"></charge-map>
                        </div>
                    </el-carousel-item>
                    <el-carousel-item style="height:100%" class="a-flex-cfsfs stretch">
                        <span class="title">过去24小时充电热力分布图</span>
                        <div class="a-flex-1 map-content a-flex-cfsfs stretch">
                            <hot-map class="a-flex-1"></hot-map>
                        </div>
                    </el-carousel-item>
                    <!-- <el-carousel-item style="height:100%" class="a-flex-cfsfs stretch">
                        <span class="title">充电桩排行</span>
                        <div class="a-flex-1 map-content a-flex-cfsfs stretch">
                            <province-sort class="a-flex-1"></province-sort>
                        </div>
                    </el-carousel-item> -->
                </el-carousel>
            </div>
            <div class="data-card a-flex-cfsfs stretch" style="flex:0.5">
                <span class="title">销售额（过去30天）</span>
                <day-trade class="a-flex-1"></day-trade>
            </div>
        </div>
        <div class="data-right a-flex-cfsc">
            <div class="data-card  a-flex-cfsfs stretch">
                <span class="title">充电次数</span>
                <charge-num class="a-flex-1"></charge-num>
            </div>
            <div class="data-card  a-flex-cfsfs stretch">
                <span class="title">客单价（元）</span>
                <customer-price class="a-flex-1"></customer-price>
            </div>
            <div class="data-card  a-flex-cfsfs stretch">
                <span class="title">车辆趋势（过去30天）</span>
                <battery-car class="a-flex-1"></battery-car>
            </div>
            <div class="data-card  a-flex-cfsfs stretch">
                <span class="title">每月交易额（过去12个月）</span>
                <month-trade class="a-flex-1"></month-trade>
            </div>
        </div>
    </div>
</template>

<script>
import BatteryCar from './components/battery-car.vue';
import DayTrade from './components/day-trade.vue';
import MonthTrade from './components/month-trade.vue'
import CustomerPrice from './components/customer-price.vue'
import ChargeNum from './components/charge-num.vue'
import ScrollNum from '../components/echarts/scrollNum.vue'
import provinceSort from './components/provinceSort.vue';
import chargeMap from './components/chargeMap.vue';
import HotMap from './components/hotMap.vue';
export default {
    components:{
        BatteryCar,
        MonthTrade,
        DayTrade,
        CustomerPrice,
        ChargeNum,
        ScrollNum,
        provinceSort,
        chargeMap,
        HotMap
    },
    data() {
        return {
            amount: '',
            orderNum: '',
            chargeNum: '',
            userNum: '',
            packageNum: '',
            packageMoney: '',
            todayInterval: null,
            bikeTotal: 0,//有效车辆
            historyMonthcardPrice: 0,//销售套餐卡金额
            historyOrderPrice: 0,//充电累计金额
            protectTotal: 0,//保护次数
            saveEnergyTotal: 0,//节约能源
            userTotal: 0,//注册用户数
        };
    },
    computed:{
        historyAmount () {
            return (this.historyOrderPrice + this.historyMonthcardPrice).toFixed(2)
        },
    },
    mounted() {
        // 设置rem
        this.setRem(); //
        window.addEventListener("resize", this.setRem);

        this.todayInterval = setInterval(()=>{
            this.getTadayDatas()
        },5000)
        this.getTotalDatas()
        
    },
    beforeDestroy () {
        document.querySelector("html").style = "";
        window.removeEventListener("resize", this.setRem);
        clearInterval(this.todayInterval)
    },
    methods: {
        resizeCharts () {
            // this.$refs.dataChart.resize();     
        },
        setRem () {
            var baseFontSize = 100;
            var baseWidth = 1920;
            var clientWidth =
                document.documentElement.clientWidth || window.innerWidth;
            var rem = 100;
            if (clientWidth != baseWidth) {
                rem = Math.floor((clientWidth / baseWidth) * baseFontSize);
            }
            document.querySelector("html").style.fontSize = rem + "px";
        },
        // 获取今日数据
        getTadayDatas () {
            let startTime = this.$getDay.getToday('YYYY-MM-DD')
            this.$Axios._post({
                url: this.$Config.apiUrl.todayDataTotal,
                method: "post",
                showLoading: false,
                params: {
                    startTime:startTime + ' 00:00:00',
                    endTime:startTime + ' 00:00:00',
                }
            }).then(res => {
                if(res.result.code == 0){         
                    this.amount = res.result.data.orderPriceTotal?Number((res.result.data.orderPriceTotal/100).toFixed(2)):0  // 订单金额总数
                    this.orderNum = res.result.data.orderTotal || 0  // 订单总数
                    this.chargeNum = res.result.data.chargingTotal || 0  // 正在充电数
                    this.userNum = res.result.data.newUserTotal || 0  // 新用户数
                    this.packageNum = res.result.data.monthcardBuyTotal || 0  // 套餐办理数
                    this.packageMoney = res.result.data.monthcardPriceTotal?Number((res.result.data.monthcardPriceTotal/100).toFixed(2)):0  // 套餐总金额数
                }else{
                    this.$message.error(res.result.message)
                }
            })
        },
        // 获取统计数据
        getTotalDatas () {
            this.$Axios._post({
                url: this.$Config.apiUrl.historyCountTotal,
                method: "post",
                showLoading: false,
                params: {}
            }).then(res => {
                if(res.result.code == 0){        
                    this.historyMonthcardPrice = res.result.data.historyMonthcardPrice?Number((res.result.data.historyMonthcardPrice/100).toFixed(2)):0  // 销售套餐卡金额
                    this.bikeTotal = res.result.data.bikeTotal || 0  // 有效车辆
                    this.protectTotal = res.result.data.protectTotal || 0  // 保护次数
                    this.saveEnergyTotal = res.result.data.saveEnergyTotal?Number(res.result.data.saveEnergyTotal).toFixed(2):0  // 节约能源
                    this.userTotal = res.result.data.userTotal || 0  // 注册用户数
                    this.historyOrderPrice = res.result.data.historyOrderPrice?Number((res.result.data.historyOrderPrice/100).toFixed(2)):0  // 充电累计金额
                    // this.historyAmount = Number(historyAmount) + Number()
                }else{
                    this.$message.error(res.result.message)
                }
            })
        }
    },
};
</script>

<style lang="scss" scoped>
    @font-face {
        font-family: unidreamLED;
        src: url("../../assets/fontFamily/UnidreamLED.ttf");
        /* IE9+  HTML节点的lang="en" 记得去掉，不然会谷歌浏览器会自动翻译导致字体不正确 */
    }
    /deep/ .el-icon-success {
        font-size: 24px;
    }
    /deep/ .el-icon-warning {
        font-size: 24px;
    }
    /deep/ .el-icon-error {
        font-size: 24px !important;
    }
    /deep/ .el-icon-info {
        font-size: 24px;
    }
    .data-title{
        font-size: 0.32rem;
        background-image:-webkit-linear-gradient(135deg, #EDBE53 0%, #F1691D 100%); 
        -webkit-background-clip:text; 
        -webkit-text-fill-color:transparent;
        font-weight: 700;
        padding: 12px 0;
    }
    .data-layout{
        background:#1D2027;
        width: 100%;
        min-height: 100vh;
        align-items: stretch;
        overflow: hidden;
        .data-left{
            width: 25%;
            align-items: stretch;
            padding-top: 0.12rem;
            padding-left: 0.06rem;
        }
        .data-center{
            width: 47%;
            align-items: stretch;
        }
        .data-right{
            width: 28%;
            padding-top: 0.12rem;
            padding-right: 0.06rem;
            align-items: stretch;
            &>div{
                height: 25%;
            }
        }
        .data-card{
            background: linear-gradient(180deg, #2C2C34 0%, #1D1E26 100%);
            border-radius: 0.12rem; 
            margin: 0 0.06rem 0.12rem;
            padding: 0.12rem 0.2rem 0;
            .title{
                font-size: 0.18rem;
                background-image:-webkit-linear-gradient(135deg, #EDBE53 0%, #F1691D 100%); 
                -webkit-background-clip:text; 
                -webkit-text-fill-color:transparent;
                margin-bottom: 0.12rem;
                font-weight: 700;
            }
        }
        .map-content{
            position: relative;
        }
        .stretch{
            align-items: stretch;
        }
        /deep/ .el-carousel__container{
            height: 100% !important;
        }
        .num-box{
            width: 50%;
            color: #FFFFFF;
            font-size: 0.14rem;
            &>span{
                padding-bottom: 0.08rem;
            }
            div{
                // padding-top: 12px;
                font-size: 0.32rem;
                font-family: unidreamLED;
                letter-spacing: 0.03rem;
            }
            .a-pl-12{
                padding-left: 0.12rem;
            }
        }
        .a-flex-2{
            flex: 1.3;
        }
    }
</style>